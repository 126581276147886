import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  getResources, MenuItemLink, usePermissions, useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as DashboardIcon } from '../assets/icons/sidebar/dashboard.svg';
import {
  ReactComponent as DashboardActiveIcon,
} from '../assets/icons/sidebar/dashboard_active.svg';
import { ReactComponent as AdminIcon } from '../assets/icons/sidebar/admin.svg';
import { ReactComponent as AdminActiveIcon } from '../assets/icons/sidebar/admin_active.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/sidebar/settings.svg';
import { ReactComponent as SettingsActiveIcon } from '../assets/icons/sidebar/settings_active.svg';
import { ReactComponent as SupportIcon } from '../assets/icons/sidebar/support.svg';
import { ReactComponent as SupportActiveIcon } from '../assets/icons/sidebar/support_active.svg';
import { ReactComponent as InvoiceIcon } from '../assets/icons/sidebar/invoice.svg';
import { ReactComponent as InvoiceActiveIcon } from '../assets/icons/sidebar/invoice_active.svg';
import PermissionUtils from '../common/permissionUtils';

const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 58,
    borderLeft: '4px solid transparent',
    fontWeight: 600,
  },
  active: {
    color: theme.palette.gray.main,
  },

}));

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  active: {
    backgroundImage: `linear-gradient(to left, ${theme.palette.primary.light}, #ffffff)`,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  ':hover': {
    display: 'none',
  },
}));

const MyMenuItem = ({
  primaryText, sidebarIsOpen, leftIcon, ...rest
}) => {
  const menuItemStyles = useMenuItemStyles();
  return (
    <MenuItemLink
      classes={menuItemStyles}
      primaryText={sidebarIsOpen ? primaryText : ''}
      leftIcon={(
        <SvgIcon
          component="span"
          style={{ width: 'unset', height: 'unset' }}
        >
          {leftIcon}
        </SvgIcon>
      )}
      {...rest}
    />
  );
};

const Menu = ({ onMenuClick }) => {
  // const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const translate = useTranslate();
  const classes = useStyles();
  const permissions = new PermissionUtils(usePermissions());
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const activeRoute = useSelector((state) => state.router.location.pathname);

  const renderResources = () => resources
    .filter((resource) => !resource.options.noMenu && (!resource.options.showFunc
      || resource.options.showFunc(permissions)
    ))
    .map((resource) => {
      const to = `/${resource.name}`;
      const isActive = activeRoute === to;
      return (
        <div key={to}>
          <MyMenuItem
            className={isActive ? classes.active : null}
            to={to}
            primaryText={
              (resource.options && translate(resource.options.label))
              || resource.name
            }
            leftIcon={isActive
              ? <resource.options.activeIcon /> : <resource.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          {resource.options?.menuItems
            ?.filter((m) => permissions.checkPermissions(m.permissions))
            .map((menuItem) => {
              const isSubActive = activeRoute === menuItem.to;
              return (
                <MyMenuItem
                  className={isSubActive ? classes.active : null}
                  key={menuItem.to}
                  to={menuItem.to}
                  primaryText={menuItem.label}
                  leftIcon={
                    isSubActive
                      ? <menuItem.activeIcon /> : <menuItem.icon />
                  }
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              );
            })}
        </div>
      );
    });

  return (
    <div className={classes.container}>
      <MyMenuItem
        key="dashboard"
        to="/dashboard"
        className={activeRoute === '/dashboard' ? classes.active : null}
        primaryText={translate('pages.dashboard.title')}
        onClick={onMenuClick}
        leftIcon={activeRoute === '/dashboard' ? <DashboardActiveIcon /> : <DashboardIcon />}
        sidebarIsOpen={open}
      />
      {renderResources()}

      {(permissions.isTenantAdmin() && !permissions.isSuperAdmin() && permissions.canEdit()) && (
        <MyMenuItem
          key="admin"
          to="/groups"
          className={activeRoute.includes('groups') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants') ? classes.active : null}
          primaryText={translate('pages.admin.title')}
          onClick={onMenuClick}
          leftIcon={activeRoute.includes('groups') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants')
            ? <AdminActiveIcon /> : <AdminIcon />}
          sidebarIsOpen={open}
        />
      )}
      {(permissions.isSuperAdmin()) && (
        <MyMenuItem
          key="admin"
          to="/super-tenants"
          className={activeRoute.includes('super-tenants') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants') ? classes.active : null}
          primaryText={translate('pages.admin.title')}
          onClick={onMenuClick}
          leftIcon={activeRoute.includes('super-tenants') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants')
            ? <AdminActiveIcon /> : <AdminIcon />}
          sidebarIsOpen={open}
        />
      )}

      {
        (((permissions.isGroupAdmin() && permissions.canEdit()))
          && !permissions.isTenantAdmin() && !permissions.isSuperAdmin()) && (
          <MyMenuItem
            key="admin"
            to="/tenants"
            className={activeRoute.includes('tenants') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants') ? classes.active : null}
            primaryText={translate('pages.admin.title')}
            onClick={onMenuClick}
            leftIcon={activeRoute.includes('tenants') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants')
              ? <AdminActiveIcon /> : <AdminIcon />}
            sidebarIsOpen={open}
          />
        )
      }

      {
        (((permissions.isFpm() && permissions.canEdit())) && !permissions.isSuperAdmin()) && (
          <MyMenuItem
            key="admin"
            to="/users"
            className={activeRoute.includes('admin') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants') ? classes.active : null}
            primaryText={translate('pages.admin.title')}
            onClick={onMenuClick}
            leftIcon={activeRoute.includes('admin') || activeRoute.includes('users') || activeRoute.includes('businessUnits') || activeRoute.includes('tenants')
              ? <AdminActiveIcon /> : <AdminIcon />}
            sidebarIsOpen={open}
          />
        )
      }

      {
        permissions.isSuperAdmin() && (
          <MyMenuItem
            key="invoice"
            to="/invoices"
            className={activeRoute.includes('invoices') ? classes.active : null}
            primaryText={translate('invoices.label')}
            onClick={onMenuClick}
            leftIcon={activeRoute.includes('invoices')
              ? <InvoiceActiveIcon /> : <InvoiceIcon />}
            sidebarIsOpen={open}
          />
        )
      }

      <MyMenuItem
        key="support"
        to="/support"
        className={activeRoute.includes('support') ? classes.active : null}
        primaryText={translate('pages.support.title')}
        onClick={onMenuClick}
        leftIcon={activeRoute.includes('support')
          ? <SupportActiveIcon /> : <SupportIcon />}
        sidebarIsOpen={open}
      />

      <MyMenuItem
        key="settings"
        to="/settings"
        className={activeRoute.includes('settings') ? classes.active : null}
        primaryText={translate('pages.settings.title')}
        onClick={onMenuClick}
        leftIcon={activeRoute.includes('settings')
          ? <SettingsActiveIcon /> : <SettingsIcon />}
        sidebarIsOpen={open}
      />
    </div>
  );
};

export default Menu;
