import React, { useEffect, useState } from 'react';
import {
  Confirm,
  ReferenceField,
  TextField,
  useDataProvider,
  useNotify,
  usePermissions,
  useRefresh,
  useShowController,
  useTranslate,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import { usePostHog } from 'posthog-js/react';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import {
  daysTillLicenseCheck,
  formatDateAsIso,
  formatIsoDate, nextLicenseCheck,
  todayIsAfter,
} from '../common/dateUtils';
import DateField from '../components/DateField';
import PopoverMenu from '../components/PopoverMenu';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';
import { ReactComponent as TrashFinalIcon } from '../assets/icons/trashFinal.svg';
import { ReactComponent as InitIcon } from '../assets/icons/init.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';
import DriverEvents from './DriverEvents';
import IndicatorCard from '../components/IndicatorCard';
import PermissionUtils from '../common/permissionUtils';
import PdfPreviewModal from '../components/PdfPreviewModal';
import DriverFilePdf from './DriverFilePdf';
import customProvider from '../providers/customProvider';
import ControlStatusIndicator from '../components/ControlStatusIndicator';
import httpClient from '../providers/httpClient';
import { baseApi } from '../common/envUtils';

const ResponsiblePersonField = ({ record }) => {
  if (!record) {
    return <span />;
  }
  return (<span>{`${record.firstName} ${record.surName}`}</span>);
};

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  cardContainer: {
    flexBasis: 420,
    marginRight: 10,
    marginBottom: 10,
    padding: '14px 42px 24px 42px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subTitle: {
    margin: '12px 0 6px 0',
  },
  emailText: {
    ...theme.typography.subtitle2,
    fontStyle: 'italic',
  },
  statusCard: {
    width: 160,
    height: 45,
  },
  paddedItem: {
    flex: 1,
    padding: '6px 6px 6px 0',
    minWidth: 150,
  },
  historyContainer: {
    maxWidth: 750,
    flex: 1,
    maxHeight: '80vh',
    overflowY: 'scroll',
    marginBottom: 10,
  },
}));

const DriverShow = (props) => {
  const classes = useStyles();
  const posthog = usePostHog();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const permissions = new PermissionUtils(usePermissions());
  const [isLoading, setLoading] = useState(false);

  const {
    loaded,
    record,
    resource,
    basePath,
  } = useShowController(props);

  const { history } = props;

  const [activeState, setActiveState] = useState({
    active: true,
    activeUntil: null,
    inactiveUntil: null,
  });
  const [uvvActive, setUvvActive] = useState(true);
  const [confirmAction, setConfirmAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pdfPreviewOpen, setPreviewOpen] = useState(false);
  const [additionalUVVs, setAdditionalUVVs] = useState([]);

  useEffect(() => {
    if (permissions.isLoggedIn() && permissions.getAdditionalUVVs()
      && additionalUVVs.length !== permissions.getAdditionalUVVs().length) {
      setAdditionalUVVs(permissions.getAdditionalUVVs());
    }
  }, [permissions]);

  useEffect(() => {
    if (record) {
      setActiveState((s) => ({
        ...s,
        active: record.active,
        activeUntil: record.activeUntil,
        inactiveUntil: record.inactiveUntil,
      }));
      setUvvActive(record.uvvActive);
    }
  }, [record]);

  const statusColors = (status) => {
    switch (status) {
      case -1:
      case 6:
      case 4:
        return 'primary';
      case 1:
      case 3:
      case 5:
        return 'yellow';
      case 2:
        return 'green';
      case 0:
        return 'red';
      default:
        return 'gray';
    }
  };

  const getFsStatusText = (status) => {
    switch (status) {
      case 4:
        return translate('drivers.events.initRequested');
      case 1:
      case 3:
      case 5:
        return translate('drivers.events.fsPending');
      case 2:
        return translate('drivers.events.fsDone');
      case 0:
        return translate('drivers.events.fsFail');
      case 6:
      default:
        return translate('drivers.events.newDriver');
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
      case 4:
      case 3:
      case 5:
        return translate('drivers.states.pending');
      case 2:
        return translate('drivers.states.success');
      case 0:
        return translate('drivers.states.fail');
      case 6:
      default:
        return translate('drivers.states.created');
    }
  };

  const getLicenseControlMethod = () => {
    if (record.driverTypeEnum === 1) {
      return translate('drivers.fields.licenseControlMethodManual');
    }
    if (!record.licenseLabelCode || record.licenseLabelCode === '00000') {
      return translate('drivers.fields.licenseControlMethodNeutral');
    }
    return translate('drivers.fields.licenseControlMethodDigital');
  };

  const handleConfirmableClick = (label, callback, content) => () => {
    setConfirmAction({
      label, callback, content,
    });
  };

  const handleActiveChange = async (e) => {
    e.persist();
    if (e && e.target) {
      setLoading(true);
      setActiveState((prevState) => ({ ...prevState, active: e.target.checked }));
      await dataProvider.update(resource, { id: record.id, data: { active: e.target.checked } })
        .then(() => {
          notify('common.success');
          refresh();
        }).catch(() => notify('common.fail')).finally(() => setLoading(false));
    }
  };

  const handleActiveUntilChange = async (date) => {
    setLoading(true);
    const activeUntil = date ? formatDateAsIso(date) : null;
    setActiveState(((prevState) => ({ ...prevState, activeUntil })));
    await dataProvider.update(resource, {
      id: record.id,
      data: { activeUntil },
    }).then(() => notify('common.success')).finally(() => setLoading(false));
  };

  const handleInactiveUntilChange = async (date) => {
    setLoading(true);
    const inactiveUntil = date ? formatDateAsIso(date) : null;
    setActiveState(((prevState) => ({ ...prevState, inactiveUntil })));
    await dataProvider.update(resource, {
      id: record.id,
      data: { inactiveUntil },
    }).then(() => notify('common.success')).finally(() => setLoading(false));
  };

  const handleUvvActiveChange = async (e) => {
    setLoading(true);
    setUvvActive(e.target.checked);
    await dataProvider.update(resource, { id: record.id, data: { uvvActive: e.target.checked } })
      .then(() => notify('common.success')).finally(() => setLoading(false));
  };

  const handleDelete = async () => {
    await dataProvider.delete(resource, { id: record.id });
    history.push(`/${resource}`);
  };

  const handleFinalDelete = async () => {
    await dataProvider.delete(resource, { id: record.id, final: true });
    history.push(`/${resource}`);
  };

  const handleRecover = async () => {
    await dataProvider.update(resource, { id: record.id, data: { deleted: false } });
    refresh();
  };

  const handleRequestInit = async () => {
    try {
      setLoading(true);
      await customProvider.customAction(resource, { id: record.id, action: 'trigger/rollout' })
        .then(() => {
          notify('pages.driverShow.initModal');
          refresh();
        });
    } finally {
      setLoading(false);
    }
  };

  const handleWelcome = async () => {
    try {
      setLoading(true);
      await customProvider.customAction(resource, { id: record.id, action: 'trigger/welcome' })
        .then(() => {
          notify('common.success');
          refresh();
        });
    } finally {
      setLoading(false);
    }
  };

  const handleRequestAdhoc = async () => {
    const request = async () => {
      setLoading(true);
      return customProvider.customAction(resource, {
        id: record.id,
        force: true,
        action: 'trigger/adhoc',
      })
        .then(() => {
          notify('common.success');
          refresh();
        }).finally(() => setLoading(false));
    };

    const amount = record.licenseIntervalDays || record.Tenant.controlIntervalDays || 180;

    if ((record.statusFS === 2 || record.statusFS === 1 || record.statusFS === 3) && record.Tenant
      && !todayIsAfter(record.statusFSUpdateDate, amount)) {
      handleConfirmableClick(translate('drivers.actions.adhoc'), request, translate('drivers.actions.stillValid', { days: daysTillLicenseCheck(record.statusFSUpdateDate, amount) }))();
    } else {
      await request();
    }
  };

  const handleRequestManual = async () => {
    setLoading(true);
    await customProvider.customAction(resource, { id: record.id, action: 'trigger/manual' })
      .then(() => {
        notify('pages.driverShow.manualModal');
        refresh();
      }).finally(() => setLoading(false));
  };

  const handleRequestUvv = async () => {
    const request = (force = false) => {
      setLoading(true);
      return customProvider.customAction(resource, {
        id: record.id,
        action: 'trigger/uvv',
        force,
      }).finally(() => setLoading(false));
    };

    const onSuccess = () => {
      refresh();
      notify('pages.driverShow.uvvModal');
    };

    try {
      const { data: initRequest } = await request();
      if (record.statusUVVUpdateDate === initRequest.statusUVVUpdateDate) {
        handleConfirmableClick(translate('drivers.actions.uvv'), () => request(true).then(
          onSuccess,
        ), translate('drivers.actions.stillValidUvv', {
          lastDate: formatIsoDate(record.statusUVVUpdateDate),
          nextDate: nextLicenseCheck(record.statusUVVUpdateDate, 365),
        }))();
      } else {
        onSuccess();
      }
    } catch (e) {
      if (record.statusUVV === 2) {
        notify('pages.driverShow.uvvFail');
      } else {
        notify('common.fail');
      }
    }
  };

  const handleRequestUvvLkw = async () => {
    const request = (force = false) => {
      setLoading(true);
      return customProvider.customAction(resource, {
        id: record.id,
        force,
        action: 'trigger/uvv-lkw',
      }).finally(() => setLoading(false));
    };

    const onSuccess = () => {
      refresh();
      notify('pages.driverShow.uvvLkwModal');
    };

    try {
      const { data: initRequest } = await request();
      if (initRequest.statusUVVLKWUpdateDate === record.statusUVVLKWUpdateDate) {
        handleConfirmableClick(
          translate('drivers.actions.uvvLkw'),
          () => request(true).then(onSuccess),
          translate('drivers.actions.stillValidUvv', {
            lastDate: formatIsoDate(record.statusUVVLKWUpdateDate),
            nextDate: nextLicenseCheck(record.statusUVVLKWUpdateDate, 365),
          }),
        )();
      } else {
        onSuccess();
      }
    } catch (e) {
      if (record.statusUVV === 2) {
        notify('pages.driverShow.uvvFail');
      } else {
        notify('common.fail');
      }
    }
  };

  const handleRequestCustomUvv = (courseId) => async () => {
    const request = (force = false) => {
      setLoading(true);
      return customProvider.customAction('c-controls', {
        id: record.id,
        courseId,
        force,
        action: 'cuvv',
      }).finally(() => setLoading(false));
    };

    const onSuccess = () => {
      refresh();
      notify('pages.driverShow.cUvv');
    };

    const controlStatus = record.ControlStatuses.find((c) => c.controlType === courseId);

    try {
      const { data: initRequest } = await request();
      if (controlStatus && controlStatus.updateDate === initRequest.updateDate) {
        handleConfirmableClick(
          `${translate(`tenants.uvvs.${courseId}`)} ${translate('tenants.uvvs.request')}`,
          () => request(true).then(onSuccess),
          translate('drivers.actions.stillValidUvv', {
            lastDate: formatIsoDate(controlStatus.updateDate),
            nextDate: nextLicenseCheck(controlStatus.updateDate, 365),
          }),
        )();
      } else {
        onSuccess();
      }
    } catch (e) {
      notify('pages.driverShow.cUvvFail');
    }
  };

  const handleCommentSave = (id, comment) => {
    httpClient(`${baseApi}/drivers/${record.id}/events/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ comment }),
    }).then(() => {
      refresh();
    });
  };

  if (!loaded || !record || isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <TitleBreadcrumbs
        path={[{ label: 'Fahrerübersicht', to: `/${resource}` }]}
        title={`${record.firstName} ${record.surName}`}
      />
      {isLoading && (<LinearProgress />)}
      <Box display="flex" flexDirection="row" flexWrap="wrap" mt={4}>
        <Paper className={classes.cardContainer}>
          <Box>
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle1" className={classes.subTitle}>
                  {translate('drivers.sections.contactInfo')}
                </Typography>
                <IconButton
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  aria-label="menu"
                >
                  <MoreVertIcon />
                </IconButton>

                <PopoverMenu
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  width={270}
                  menuOptions={[
                    {
                      icon: (
                        <SvgIcon>
                          <UserIcon />
                        </SvgIcon>),
                      label: translate('drivers.actions.welcome'),
                      onClick: handleWelcome,
                      hidden: !permissions.canEdit(),
                    },
                    {
                      icon: (
                        <SvgIcon>
                          <EditIcon />
                        </SvgIcon>),
                      label: translate('drivers.actions.edit'),
                      onClick: () => {
                        history.push(`/drivers/${record.id}/edit`);
                      },
                      hidden: !permissions.canEdit(),
                    },
                    {
                      icon: (
                        <SvgIcon>
                          <DownloadIcon />
                        </SvgIcon>
                      ),
                      label: translate('drivers.actions.download'),
                      onClick: () => {
                        setPreviewOpen(true);
                      },
                    },
                    {
                      icon: (
                        <SvgIcon>
                          <InitIcon />
                        </SvgIcon>
                      ),
                      label: translate('drivers.actions.rollout'),
                      onClick: handleConfirmableClick(translate('drivers.actions.rollout'), handleRequestInit),
                      disabled: record.statusFS !== -1,
                      hidden: !permissions.canEdit() || !permissions.hasPhotoRollout(),
                    },
                    record.deleted ? {
                      icon: (
                        <SvgIcon>
                          <TrashIcon />
                        </SvgIcon>),
                      label: translate('drivers.actions.recover'),
                      onClick: handleConfirmableClick(
                        translate('drivers.actions.recover'),
                        handleRecover,
                      ),
                      hidden: !permissions.isSuperAdmin(),
                    } : {
                      icon: (
                        <SvgIcon>
                          <TrashIcon />
                        </SvgIcon>),
                      label: translate('drivers.actions.delete'),
                      onClick: handleConfirmableClick(
                        translate('drivers.actions.delete'),
                        handleDelete,
                      ),
                      hidden: !permissions.canEdit() || !permissions.canManage(),
                    },
                    {
                      icon: (
                        <SvgIcon>
                          <TrashFinalIcon />
                        </SvgIcon>
                      ),
                      label: translate('drivers.actions.deleteFinal'),
                      onClick: handleConfirmableClick(translate('drivers.actions.deleteFinal'), handleFinalDelete),
                      hidden: !permissions.isSuperAdmin(),
                    },
                  ]}
                />
              </Box>

              <Typography variant="h6">{`${record.firstName} ${record.surName}`}</Typography>
              <Typography
                variant="body1"
              >
                {translate(`drivers.contactType.${record.contactType}`)}
              </Typography>
              <Typography className={classes.emailText}>{record.email}</Typography>
              <Typography className={classes.emailText}>{record.phoneNumber}</Typography>

              {(record.driverTypeEnum !== null) && (
                <Typography
                  variant="body1"
                >
                  {translate(`drivers.driverTypes.${record.driverTypeEnum}`)}
                </Typography>
              )}

              {(record.isPkw) && (
                <Typography
                  variant="body1"
                >
                  {translate('drivers.fields.isPkw')}
                </Typography>
              )}

              {(record.isLkw) && (
                <Typography
                  variant="body1"
                >
                  {translate('drivers.fields.isLkw')}
                </Typography>
              )}

              {(permissions.isGroupAdmin()) && (
                <Box display="flex" flexDirection="row">
                  <Typography variant="body1">
                    {`${translate('tenants.label')}:`}
                    &nbsp;
                  </Typography>
                  <ReferenceField
                    basePath={basePath}
                    record={record}
                    resource={resource}
                    source="fk_tenantId"
                    reference="tenants"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Box>
              )}

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Box mr={3}>
                  <Typography
                    variant="subtitle2"
                  >
                    {translate('pages.driverShow.statusFS')}
                  </Typography>
                  <IndicatorCard
                    classes={{ container: classes.statusCard }}
                    color={statusColors(record.statusFS)}
                  >
                    <Box flex={1} pl={2} py={1}>
                      <Typography variant="h6">{getFsStatusText(record.statusFS)}</Typography>
                    </Box>
                  </IndicatorCard>
                </Box>

                {permissions.isIncludeUvv() && uvvActive && record.isPkw && (
                  <Box>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('pages.driverShow.statusUVV')}
                    </Typography>
                    <IndicatorCard
                      classes={{ container: classes.statusCard }}
                      color={statusColors(record.statusUVV)}
                    >
                      <Box flex={1} pl={2} py={1}>
                        <Typography variant="h6">{getStatusText(record.statusUVV)}</Typography>
                      </Box>
                    </IndicatorCard>
                  </Box>
                )}

                {permissions.isIncludeUvvLkw() && record.uvvLkwActive && record.isLkw && (
                  <Box>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('pages.driverShow.statusUVVLKW')}
                    </Typography>
                    <IndicatorCard
                      classes={{ container: classes.statusCard }}
                      color={statusColors(record.statusUVVLKW)}
                    >
                      <Box flex={1} pl={2} py={1}>
                        <Typography
                          variant="h6"
                        >
                          {getStatusText(record.statusUVVLKW)}
                        </Typography>
                      </Box>
                    </IndicatorCard>
                  </Box>
                )}

                {additionalUVVs.map((ct) => (
                  <ControlStatusIndicator
                    key={ct}
                    record={record}
                    controlType={ct}
                  />
                ))}

              </Box>

              {record.scheduledEventDate && (
                <>
                  <Typography
                    variant="subtitle2"
                  >
                    {translate('drivers.fields.scheduledEventDate')}
                  </Typography>
                  <Typography
                    variant="body1"
                  >
                    {formatIsoDate(record.scheduledEventDate)}
                  </Typography>
                </>
              )}

              <Typography variant="subtitle2" className={classes.paddedItem}>Aktivität</Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={activeState.active}
                      onChange={handleActiveChange}
                      color="primary"
                      disabled={!permissions.canEdit()}
                    />
                  )}
                  label={(
                    <Typography variant="body1">{translate('drivers.fields.active')}</Typography>)}
                />
                <DateField
                  style={{ flex: 2, marginRight: 5 }}
                  margin="dense"
                  label={translate('drivers.fields.activeUntil')}
                  value={activeState.activeUntil}
                  onChange={handleActiveUntilChange}
                  disabled={!activeState.active || !permissions.canEdit()}
                  clearable
                />
                <DateField
                  style={{ flex: 2 }}
                  margin="dense"
                  label={translate('drivers.fields.inactiveUntil')}
                  value={activeState.inactiveUntil}
                  onChange={handleInactiveUntilChange}
                  disabled={(activeState.active && !activeState.activeUntil)
                    || !permissions.canEdit()}
                  clearable
                />
              </Box>
              {permissions.isIncludeUvv() && (record.driverTypeEnum === 0
                  || permissions.isType1Uvv())
                && (
                  <Box>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={uvvActive}
                          onChange={handleUvvActiveChange}
                          color="primary"
                          disabled={!permissions.canEdit()}
                        />
                      )}
                      label={(
                        <Typography
                          variant="body1"
                        >
                          {translate('drivers.fields.uvvActive')}
                        </Typography>
                      )}
                    />
                  </Box>
                )}
            </Box>

            <Box>
              <Typography variant="subtitle1" className={classes.subTitle}>
                {translate('drivers.sections.licenseInfo')}
              </Typography>
              <Box display="flex" flexDirection="row" flexWrap="wrap">

                {Boolean(record.licenseIntervalDays) && (
                  <div className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('drivers.fields.licenseInterval')}
                    </Typography>

                    {record.licenseIntervalDays > 3
                      ? (
                        <Typography variant="body1">
                          {Math.round(record.licenseIntervalDays / 30)}
                          {' '}
                          {translate('drivers.fields.licenseIntervalPostfix')}
                        </Typography>
                      )
                      : (
                        <Typography variant="body1">
                          {record.licenseIntervalDays}
                          {' '}
                          {translate('drivers.fields.licenseIntervalPostfixDays')}
                        </Typography>
                      )}

                  </div>
                )}

                {(record.uvvActive || record.uvvLkwActive) && (
                  <div className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('tenants.fields.uvvIntervalDays')}
                    </Typography>

                    {record.uvvIntervalDays > 3
                      ? (
                        <Typography variant="body1">
                          {Math.round(record.uvvIntervalDays / 30)}
                          {' '}
                          {translate('drivers.fields.licenseIntervalPostfix')}
                        </Typography>
                      )
                      : (
                        <Typography variant="body1">
                          {record.uvvIntervalDays}
                          {' '}
                          {translate('drivers.fields.licenseIntervalPostfixDays')}
                        </Typography>
                      )}

                  </div>
                )}

                {record.licenseLabelCode && (record.licenseLabelCode !== '00000' || !posthog.isFeatureEnabled('neutral-driver-license-state')) && record.driverTypeEnum === 0 && (
                  <div className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('drivers.fields.licenseLabelCode')}
                    </Typography>
                    <Typography variant="body1">
                      {record.licenseLabelCode}
                    </Typography>
                  </div>
                )}
                {posthog.isFeatureEnabled('neutral-driver-license-state') && (
                  <div className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('drivers.fields.licenseControlMethod')}
                    </Typography>
                    <Typography variant="body1">
                      {getLicenseControlMethod()}
                    </Typography>
                  </div>
                )}
                {record.loginCode && (
                  <div className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('drivers.fields.loginCode')}
                    </Typography>
                    <Typography variant="body1">
                      {record.loginCode}
                    </Typography>
                  </div>
                )}

                {record.licenseType !== null && (
                  <div className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('drivers.fields.licenseType')}
                    </Typography>
                    <Typography variant="body1">
                      {translate(`drivers.licenseType.${record.licenseType}`)}
                    </Typography>
                  </div>
                )}

                {record.licenseExpiryDate && (
                  <div className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('drivers.fields.licenseExpiryDate')}
                    </Typography>
                    <Typography variant="body1">
                      {record.licenseExpiryDate
                        ? formatIsoDate(record.licenseExpiryDate)
                        : ''}
                    </Typography>
                  </div>
                )}

                {record.statusFSVisualControlText && (
                  <Box width="100%" className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {translate('drivers.fields.statusFSVisualControlText')}
                    </Typography>
                    <Typography variant="body1">
                      {record.statusFSVisualControlText}
                    </Typography>
                  </Box>
                )}

                {record.licenseComment1 && (
                  <Box width="100%" className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {(record.Tenant && record.Tenant.commentField1Name) || translate('drivers.fields.licenseComment1')}
                    </Typography>
                    <Typography variant="body1">
                      {record.licenseComment1}
                    </Typography>
                  </Box>
                )}

                {record.licenseComment2 && record.Tenant && record.Tenant.commentField2Name && (
                  <Box width="100%" className={classes.paddedItem}>
                    <Typography
                      variant="subtitle2"
                    >
                      {record.Tenant.commentField2Name || translate('drivers.fields.licenseComment2')}
                    </Typography>
                    <Typography variant="body1">
                      {record.licenseComment2}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                className={classes.subTitle}
              >
                {translate('drivers.sections.responsible')}
              </Typography>

              {(!record.adminMailAddress && !record.adminName
                && record.Tenant && record.Tenant.responsibleUserId) ? (
                  <ReferenceField
                    basePath={basePath}
                    record={record}
                    resource={resource}
                    source="Tenant.responsibleUserId"
                    reference="users"
                  >
                    <ResponsiblePersonField />
                  </ReferenceField>
                ) : (
                  <Box display="flex" flexDirection="row" flexWrap="wrap">

                    {(record.adminMailAddress && record.adminName) && (
                    <div className={classes.paddedItem}>
                      <Typography
                        variant="subtitle2"
                      >
                        {translate('drivers.sections.adminName')}
                      </Typography>
                      <Typography variant="body1">
                        {record.adminName}
                      </Typography>
                    </div>
                    )}

                    {(record.adminMailAddress && record.adminName) && (
                    <div className={classes.paddedItem}>
                      <Typography
                        variant="subtitle2"
                      >
                        {translate('drivers.sections.adminMailAddress')}
                      </Typography>
                      <Typography variant="body1">
                        {record.adminMailAddress}
                      </Typography>
                    </div>
                    )}
                  </Box>
                )}

            </Box>
          </Box>

          {record.active && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              flexWrap="wrap"
              mt={2}
            >
              {record.driverTypeEnum === 0 && (
                <Button
                  disabled={!permissions.canEdit()}
                  onClick={handleConfirmableClick(
                    translate('drivers.actions.adhoc'),
                    handleRequestAdhoc,
                    !record.licenseLabelCode || record.licenseLabelCode === '00000'
                      ? (
                        <Box maxWidth="350px">
                          <Typography color="secondary">
                            {translate('drivers.actions.invalidLabelCode')}
                          </Typography>
                        </Box>
                      )
                      : undefined,
                  )}
                >
                  {translate('drivers.actions.adhoc')}
                </Button>
              )}

              <Button
                disabled={!permissions.canEdit()}
                onClick={handleConfirmableClick(
                  translate('pages.driverShow.manualButton'),
                  handleRequestManual,
                  translate('pages.driverShow.manualConfirm'),
                )}
              >
                {translate('pages.driverShow.manualButton')}
              </Button>

              {(permissions.isIncludeUvv() && record.uvvActive && record.isPkw) && (
                <Button
                  id="uvvButton"
                  disabled={!permissions.canEdit()}
                  onClick={handleConfirmableClick(translate('drivers.actions.uvv'), handleRequestUvv)}
                >
                  {translate('drivers.actions.uvv')}
                </Button>
              )}

              {(permissions.isIncludeUvvLkw() && record.uvvLkwActive && record.isLkw) && (
                <Button
                  id="uvvLkwButton"
                  disabled={!permissions.canEdit()}
                  onClick={handleConfirmableClick(translate('drivers.actions.uvvLkw'), handleRequestUvvLkw)}
                >
                  {translate('drivers.actions.uvvLkw')}
                </Button>
              )}

              {additionalUVVs.map((ct) => (
                <Button
                  id={`${ct}-button`}
                  key={ct}
                  disabled={!permissions.canEdit()}
                  onClick={handleConfirmableClick(
                    `${translate(`tenants.uvvs.${ct}`)} ${translate('tenants.uvvs.request')}`,
                    handleRequestCustomUvv(ct),
                  )}
                >
                  {`${translate(`tenants.uvvs.${ct}`)} ${translate('tenants.uvvs.request')}`}
                </Button>
              ))}
            </Box>
          )}
        </Paper>

        {record.Events && (
          <Paper className={classes.historyContainer}>
            <DriverEvents events={record.Events} onSave={handleCommentSave} />
          </Paper>
        )}
      </Box>

      <PdfPreviewModal
        fileName={`${record.firstName}_${record.surName}`}
        open={pdfPreviewOpen}
        onClose={() => setPreviewOpen(false)}
        document={(
          <DriverFilePdf
            translate={translate}
            driver={record}
            includeUvv={permissions.isIncludeUvv()}
          />
        )}
      />

      <Confirm
        isOpen={Boolean(confirmAction)}
        title={confirmAction ? confirmAction.label : ''}
        content={confirmAction && confirmAction.content ? confirmAction.content : translate('common.confirm')}
        onConfirm={confirmAction ? () => {
          setConfirmAction(null);
          confirmAction.callback();
        } : () => {
        }}
        onClose={() => setConfirmAction(null)}
      />

    </div>
  );
};

export default DriverShow;
